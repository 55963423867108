import code from "@lekoarts/gatsby-theme-minimal-blog/src/styles/code";

const fontFamily = `'menlo', 'monaco', 'monospace'`;

code[".prism-code"]["fontFamily"] = fontFamily;
code[".prism-code"]["fontSize"] = 1;

code[".code-title"]["fontFamily"] = fontFamily;

code["figcaption"] = {
  fontSize: 1,
  mt: 2,
  backgroundColor: `transparent`,
  textAlign: `center`,
  color: `secondary`,
};

code["summary"] = {
  fontSize: 2,
  paddingLeft: 2,
  backgroundColor: `#ffeeee`,
  fontWeight: 600,
  ":hover": {
    cursor: `pointer`,
  }
}

export default code

